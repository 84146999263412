.MainContainer {
  margin-top: 100px;
  z-index: 9999;
  position: fixed;
  right: 88px;
  bottom: 3vh;
  padding: 0;
  border: none;
  background: #2196f3;
  cursor: pointer;
  width: 320px;
  opacity: 1;
  border-radius: 11px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  /* border: 2px solid ; */
  transition: width 2s;
  transition-timing-function: ease;
}
.MainContainerHidden {
  margin-top: 100px;

  z-index: 9999;
  position: fixed;
  right: 10vh;
  bottom: 3vh;
  padding: 0;
  border: none;
  box-shadow: none;
  background: #fff;
  cursor: pointer;

  width: 350px;
  opacity: 1;
  border-radius: 11px;
  background: #ffffff;
  box-shadow: 5px 5px 20px #ebebeb, -5px -5px 20px #ffffff;
  display: none;
}
.botArea .MuiAccordion-rounded{
  background: #429efa !important;
}
.botArea .MuiSvgIcon-root{
  color: white !important;
}
.botArea .MuiCollapse-wrapper{
  display: block !important;
}
.chatArea {
  width: 400px;
  height: 260px;
  opacity: 1;
}
#videoBox {
  width: 320px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#myVideo {
  top: 214px;
  left: 1368px;
  width: 370px;
  height: 40px;
  opacity: 1;
}

.inputArea {
  /* top: 1938px; */
  margin-top: 250px;
  margin-left: 10px;
  margin-right: 10px;
  /* left: 1388px; */
  width: 370px;
  height: 40px;
  border: 2px solid #1a8dff;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 12px 6px 12px #0000001f;
  border-radius: 28px 28px 28px 28px;
  opacity: 1;
}
.micBox {
  /* background-color: #1A8DFF; */
}
.micButtonImage {
  padding-top: 5px;
  width: 20px;
  height: 30px;
}
.chatList {
  padding: "0 20px";
  width: 100%;
  height: 100vh;
  opacity: 1;
  display: "flex";
  justifycontent: "end";
}
.botHeader {
  text-align: left;
  margin-left: 10px;
  font: normal normal medium 10px/12px SF Pro Display;
  letter-spacing: 0px;
  color: #000000;
}
.span-green {
  color: #1aff00;
  font-weight: bolder;
  font-size: 18pt;
}
#mainContainerModal{
  /* height: 100%; */
  /* z-index : 1000;     */
  /* position: absolute; */
  /* right: 75px; */
  /* width: 100%; */
  /* transition: width 1s ease-in */
}

@media only screen and (max-width: 480px) {
  .MainContainer {
    /* z-index:9999; */
    position: fixed;
    right: 3vh;
    bottom: 10vh;
    /* border-radius: 50%; */
    padding: 0;
    margin-top: 100px;

    position: fixed;

    padding: 0;
    /* width: 50px;
    height: 50px; */
    border: none;
    box-shadow: none;
    background: #2196f3;
    cursor: pointer;

    /* margin-right: -50px; */
    width: 350px;
    /* height:70vh; */
    /* height: 530px; */
    opacity: 1;
    border-radius: 11px;
    background: #ffffff;
    /* background: black; */
    box-shadow: 5px 5px 20px #ebebeb, -5px -5px 20px #ffffff;
  }
}
