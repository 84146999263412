
@keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
  }
}

@keyframes home-banner {
  0% {
    background-image: url("./media/slide4.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 1;
  }
  /* 2.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 101%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.9;
  }
  5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 102%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.9;
  }
  7.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 103%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.8;
  }
  10%{
    background-image: url('./media/slide4.webp'); 
    background-size: 104%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.8;
  }
  12.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 105%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.7;
  }
  15%{
    background-image: url('./media/slide4.webp'); 
    background-size: 106%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.7;
  }
  17.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 107%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.6;
  }
  20%{
    background-image: url('./media/slide4.webp'); 
    background-size: 108%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.6;
  }
  22.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 109%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.5;
  } */
  25% {
    background-image: url("./media/slide4.webp");
    background-size: 110%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.5;
  }
  27.5% {
    background-image: url("./media/slide5.webp");
    background-size: 110%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.5;
  }
  /* 30%{
    background-image: url('./media/slide5.webp'); 
    background-size: 109%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.5;
  }
  32.5%{
    background-image: url('./media/slide5.webp'); 
    background-size: 108%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.6;
  }
  35%{
    background-image: url('./media/slide5.webp'); 
    background-size: 107%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.6;
  }
  37.5%{
    background-image: url('./media/slide5.webp'); 
    background-size: 106%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.6;
  }
  40%{
    background-image: url('./media/slide5.webp'); 
    background-size: 105%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.7;
  }
  42.5%{
    background-image: url('./media/slide5.webp'); 
    background-size: 104%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.8;
  }
  45%{
    background-image: url('./media/slide5.webp'); 
    background-size: 103%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.8;
  }
  47.5%{
    background-image: url('./media/slide5.webp'); 
    background-size: 102%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.9;
  }
  50%{
    background-image: url('./media/slide5.webp'); 
    background-size: 101%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.9;
  } */
  52.5% {
    background-image: url("./media/slide5.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 0.9;
  }
  55% {
    background-image: url("./media/slide6.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 1;
  }
  /* 57.5%{
    background-image: url('./media/slide6.webp'); 
    background-size: 101%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 1.0;
  }
  60%{
    background-image: url('./media/slide6.webp'); 
    background-size: 102%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
    opacity: 1.0;
  }
  62.5%{
    background-image: url('./media/slide6.webp'); 
    background-size: 103%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  65%{
    background-image: url('./media/slide6.webp'); 
    background-size: 104%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  67.5%{
    background-image: url('./media/slide6.webp'); 
    background-size: 105%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  70%{
    background-image: url('./media/slide6.webp'); 
    background-size: 106%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  72.5%{
    background-image: url('./media/slide6.webp'); 
    background-size: 107%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  75%{
    background-image: url('./media/slide6.webp'); 
    background-size: 108%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  77.5%{
    background-image: url('./media/slide6.webp'); 
    background-size: 109%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  } */
  80% {
    background-image: url("./media/slide6.webp");
    background-size: 110%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  82.5% {
    background-image: url("./media/slide4.webp");
    background-size: 110%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  /* 85%{
    background-image: url('./media/slide4.webp'); 
    background-size: 109%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  87.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 108%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  90%{
    background-image: url('./media/slide4.webp'); 
    background-size: 107%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  92.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 106%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  95%{
    background-image: url('./media/slide4.webp'); 
    background-size: 105%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  96.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 104%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  97.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 103%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  98.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 102%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
  99.5%{
    background-image: url('./media/slide4.webp'); 
    background-size: 101%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }   */
  100% {
    background-image: url("./media/slide4.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    transition: all 1s ease-in-out;
  }
}
@-webkit-keyframes swing-paper-right {
  0% {
    -webkit-transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
  }
}

@keyframes swing-paper-right {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@-webkit-keyframes swing-paper-left {
  0% {
    -webkit-transform: rotate(-5deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(-5deg);
  }
}

@keyframes swing-paper-left {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@keyframes bulb {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.6;
  }
  20% {
    opacity: 0.7;
  }
  30% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.9;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0.9;
  }
  70% {
    opacity: 0.8;
  }
  80% {
    opacity: 0.7;
  }
  90% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
}
@keyframes career {
  0% {
    opacity: 0.4;
    right: 0;
  }
  10% {
    opacity: 0.7;
    right: 50%;
  }
  20% {
    opacity: 1;
    transform: rotate(45deg);
  }
  30% {
    right: 60%;
    bottom: 50px;
    transform: rotate(60deg);
  }
  40% {
    right: 50%;
    bottom: 70px;
  }
  50% {
    right: 40%;
    bottom: 100px;
    transform: rotate(90deg);
  }
  60% {
    right: 30%;
    bottom: 150px;
    transform: rotate(60deg);
  }
  70% {
    right: 50%;
    bottom: 100px;
    transform: rotate(60deg);
  }
  90% {
    right: 60%;
    bottom: 50px;
    opacity: 0.7;
    transform: rotate(45deg);
  }
  80% {
    right: 60%;
    bottom: 50px;
    transform: rotate(60deg);
  }
  100% {
    right: 0;
    bottom: 0px;
    opacity: 0;
    transform: rotate(0deg);
  }
}
@keyframes career-crowd {
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1.3);
  }
  60% {
    transform: scale(1.4);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spin-changer {
  0% {
    -webkit-transform: translateZ(63px);
    -moz-transform: translateZ(63px);
    transform: translateZ(63px);
  }
  27% {
    -webkit-transform: rotateX(360deg) rotateY(-90deg);
    -moz-transform: rotateX(360deg) rotateY(-90deg);
    transform: rotateX(360deg) rotateY(-90deg);
  }
  43% {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  59% {
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
  77% {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: rotateX(-90deg) rotateY(-360deg);
    -moz-transform: rotateX(-90deg) rotateY(-360deg);
    transform: rotateX(-90deg) rotateY(-360deg);
  }
  93% {
    -webkit-transform: rotateX(90deg) rotateY(360deg);
    -moz-transform: rotateX(90deg) rotateY(360deg);
    transform: rotateX(90deg) rotateY(360deg);
  }
}

@font-face {
  font-family: "webfontregular";
  src: url("./fonts/proximanova-bold-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "webfontregular";
  src: url("./fonts/proximanova-semibold-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "webfontregular-italic";
  src: url("./fonts/proximanova-bold-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "webfontregular-italic";
  src: url("./fonts/proximanova-semibold-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
:root {
  /* --font-family-italic: 'Lora', serif;
  --font-family-normal: 'Noto Sans', sans-serif;
  --font-family-italic: 'webfontregular-italic' , sans-serif;
  --font-family-normal: 'webfontregular' , sans-serif; */
  font-family: "Poppins", sans-serif;
  --color-yellow: #fbb333;
  --color-blue: #08a8f2;
  --color-dark-blue: #10337c;
  --gradient-blue: linear-gradient(to right, #10337c, #08a8f2);
  --gradient-blue-reverse: linear-gradient(to right, #08a8f2, #10337c);
}
body {
  position: relative;
  /* --font-family-italic: 'webfontregular-italic' , sans-serif;
  --font-family-normal: 'webfontregular' , sans-serif; */
}
body.active:after {
  background: #4588dcb8;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}
body .flag-block {
  position: absolute;
  right: 0;
  margin: 0;
  align-items: center;
  top: 0px;
  font-size: 13px;
  color: #987f7f;
}
body .flag-block img {
  width: 30px;
  box-shadow: 0 0 10px #ececec;
  height: 100%;
  object-fit: contain;
}
.title-banner-bar {
  position: relative;
  display: inline-block;
  font-family: var(--font-family-italic);
  /* font-weight: 300; */
  font-size: 60px;
  color: #ffffff;
  text-shadow: 0 10px 10px #00000030;
  margin-bottom: 15px;
}
.title-banner-bar:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 5px;
  bottom: -10px;
  background-color: #08a8f2;
}
.title-yellow-bar {
  position: relative;
  display: inline-block;
  font-family: var(--font-family-italic);
  /* font-weight: 300; */
  font-size: 22px;
  color: #ffffff;
  text-shadow: 0 10px 10px #00000030;
  margin-bottom: 15px;
}
.title-yellow-bar:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  bottom: -10px;
  background-color: #08a8f2;
}
.title-blue-bar {
  position: relative;
  /* font-weight: 300; */
  font-size: 22px;
  font-family: var(--font-family-italic);
  display: inline-block;
  color: var(--color-blue);
  margin-bottom: 12px;
}
.title-blue-bar:before {
  position: absolute;
  content: "";
  background-color: var(--color-blue);
  box-shadow: 0px 0px 3px #88d8fd;
  border: 2px solid #08a8f2;
  width: 100%;
  height: 3px;
  bottom: -10px;
}
.overview-black {
  font: var(--font-family-normal);
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0.05rem;
}
.overview-white {
  font: var(--font-family-normal);
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0.05rem;
}
.testo-img {
  margin: auto;
  padding: 12px;
  height: 60%;
  width: 100%;
}
.testo-content {
  background: var(--gradient-blue);
  font-family: var(--font-family-normal);
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.1rem;
  line-height: 2;
  padding: 100px 25px 25px;
}
.blue-border {
  border: 2px solid var(--color-blue);
  box-shadow: 0px 3px 36px #00000024;
  margin: 1% 10%;
  border-radius: 4px;
}
.yellow-border {
  border: 2px solid var(--color-yellow);
  box-shadow: 0px 3px 36px #00000024;
  margin: 1% 10%;
  border-radius: 4px;
}
.engag-model-block {
  border: 3px solid #ffffff;
  border-radius: 8px;
  box-shadow: 1px 1px 9px #00000024;
  padding: 30px;
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.single-model .engag-model-block {
  max-width: calc(100% - 30px);
  margin: 0 auto;
}
.engag-model-block p.engag-model-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
}
.engag-model-block p.engag-model-icon span.engage-icons {
  max-width: 100%;
  background-size: 100%;
  background-image: url("./media/engage-icons.webp");
}
.engag-model-block p.engag-model-icon span.engage-icons {
  width: 70px;
  height: 70px;
}
.engag-model-block p.engag-model-icon span.engage-icons1 {
  background-position: 0 0%;
  background-size: 100%;
}
.engag-model-block p.engag-model-icon span.engage-icons2 {
  background-position: 0 47.826087%;
  background-size: 104.255319%;
}
.engag-model-block p.engag-model-icon span.engage-icons3 {
  background-position: 10px 100%;
  background-size: 90.512195%;
}
/* .engag-model-block p.engag-model-icon svg , .engag-model-block p.engag-model-icon img{
  width: 70px;
  height: 70px;
} */
.engag-model-icon.blue {
  background: var(--color-blue);
}
.engag-model-icon.yellow {
  background: var(--color-yellow);
}
.engag-model-icon.dark-blue {
  background: var(--color-dark-blue);
}
.engag-model-heading {
  font: var(--font-family-normal);
  font-weight: 500;
  font-size: 20px;
  margin: 10px auto;
  line-height: 1;
}
.engag-model-heading.blue {
  color: var(--color-blue);
}
.engag-model-heading.yellow {
  color: var(--color-yellow);
}
.engag-model-heading.dark-blue {
  color: var(--color-dark-blue);
}
.job-icon-blue {
  border-radius: 50%;
  background: #08a8f226;
}
.job-icon-yellow {
  border-radius: 50%;
  background: #fbb33317;
}
.job-icon-img {
  height: 100%;
  width: 100%;
  padding: 20%;
}
.job-title-blue {
  font-family: var(--font-family-normal);
  font-size: 20px;
  font-weight: 500;
  color: var(--color-blue);
  text-transform: uppercase;
}
.job-title-yellow {
  font-family: var(--font-family-normal);
  font-size: 20px;
  font-weight: 600;
  color: var(--color-yellow);
  text-transform: uppercase;
}
.job-deadline-blue {
  font-family: var(--font-family-normal);
  font-size: 15px;
  font-style: italic;
  color: var(--color-blue);
}
/* Header CSS Start */
.web-head {
  /* position: sticky;
  top: 0; */
  background: #fff;
  z-index: 999999;
  border-bottom: 1px solid #ececec;
}
#header {
  display: inline-block;
  width: 100%;
  font-family: var(--font-family-normal);
  font-weight: 400;
  color: #000000;
}
#header nav.navbar > a.active svg {
  width: 70px;
}
#header .navbar .navbar-nav .nav-link {
  padding: 10px 25px;
  text-align: center;
  cursor: pointer;
}
#header .navbar .navbar-nav .nav-link.active {
  color: var(--color-blue);
}
#header .navbar .navbar-nav a.button {
  background: #83c4f3;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: large;
  min-width: 100px;
  width: 160px;
  height: 52px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
/* Header CSS End */

/* Landing Slider */
#slider-sec
  .slick-slider
  .slick-list
  .slick-slide
  div.slide-content
  .sub-title {
  color: var(--color-yellow);
  font-size: 22px;
  font-weight: 300;
  font-family: var(--font-family-italic);
  margin: 10px 0;
}
#slider-sec .slick-slider .slick-list .slick-slide div.slide-content p {
  font-size: 19px;
}
#slider-sec .slick-slider .slick-list .slick-slide div.slide-content p svg {
  margin: 0 5px 10px;
}
#slider-sec .slick-slider .slick-list .slick-slide .slide-content-block {
  position: relative;
  height: 700px;
  width: 100%;
  display: inline-flex !important;
  justify-content: flex-start;
  align-items: center;
}
#slider-sec .slick-slider .slick-list .slick-slide::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--gradient-blue);
  opacity: 0.02;
}
#slider-sec .slick-slider .slick-list .slick-slide div.slide-content {
  position: relative;
  color: #ffffff;
  z-index: 999;
  padding: 15px 10px;
  background: #00000082;
}
#slider-sec .slick-slider .slick-list .slick-slide img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  bottom: 0;
}
#slider-sec ul.slick-dots li {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 120px;
  margin: -5px;
}
#slider-sec ul.slick-dots li button {
  width: 35px;
  height: 10px;
  border-radius: 3px;
  background: #fff;
  opacity: 0.3;
}
#slider-sec ul.slick-dots li button::before {
  color: transparent;
}
#slider-sec ul.slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.home-banner-sec {
  height: 700px;
  min-height: 260px;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  animation-name: home-banner;
  animation-duration: 50s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}
/* .home-banner-sec .home-content-block{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  background-image: url('./media/slide4.webp'); 
  background-size: 100%;
  background-repeat: no-repeat;
  animation-name: home-banner;
  animation-duration: 50s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
} */
.cloud-container{
  display:flex;
  flex-direction: column;
  text-align:center;
  align-items: center;
}
.cloud-container h5{
  padding: 12px 0px 12px 0px;
  width: 60%;
  color: white;
  font-size: 20px;
  font-weight: 600;
  background: #08a8f2;
  border-radius: 4px;
  margin-bottom: 20px;
}

.home-banner-sec:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    0deg,
    rgba(8, 136, 242, 0.5),
    rgba(0, 12, 38, 0.7)
  );
}
.home-banner-sec div.content-block {
  position: relative;
  color: #ffffff;
  z-index: 999;
  padding: 15px 10px;
  /* background: #00000082; */
}
.home-banner-sec div.content-block .sub-title {
  color: var(--color-yellow);
  font-size: 22px;
  font-weight: 300;
  font-family: var(--font-family-italic);
  margin: 10px 0;
  display: none;
}
.home-banner-sec div.content-block p {
  font-size: 28px;
}
.home-banner-sec div.content-block p svg {
  margin: 0 5px 10px;
  width: 10px;
}

/* Landing Slider */

/* Our Products Section Start */
#our-products-sec {
  position: relative;
  z-index: 9999;
}
/* #our-products-sec img.img-bg{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75%;
  max-width: 100%;
  max-height: 100%;
  z-index: -1;
  object-fit: none;
} */
section#our-products-sec:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75%;
  max-width: 100%;
  max-height: 100%;
  z-index: -1;
  object-fit: none;
  background: linear-gradient(130deg, #1c3a85, #53a5ef);
}
#our-products-sec .product-sec-desc {
  padding: 50px 0 15px;
}
#our-products-sec .product-sec-desc .slick-buttons button {
  background: transparent;
  color: #ffffff;
  border: 3px solid #ffffff;
  border-radius: 7px;
  margin: 7px;
}
#our-products-sec .slick-slider .slick-list .slick-slide {
  padding: 0 25px;
}
#our-products-sec .slick-slider .slick-list .slick-slide .product {
  position: relative;
  /* border: 10px solid transparent; */
  min-height: 400px;
  border-radius: 15px;
  /* box-shadow: 0 0 10px #ececec; */
  overflow: hidden;
  margin-bottom: 15px;
  max-width: 350px;
  margin: 0 auto;
}
#our-products-sec .slick-slider .slick-list .slick-slide .product:before {
  position: absolute;
  content: "";
  top: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(0deg, #000000, #ffffff21);
  transition: all 0.5s;
}
#our-products-sec .slick-slider .slick-list .slick-slide .product:hover:before {
  top: 0;
  transition: all 0.5s;
}
#our-products-sec
  .slick-slider
  .slick-list
  .slick-slide
  .product
  .lazy-load-image-background {
  width: 100%;
  height: 100%;
}
#our-products-sec
  .slick-slider
  .slick-list
  .slick-slide
  .product
  .lazy-load-image-background
  img {
  width: 100%;
}
/* #our-products-sec .slick-slider .slick-list .slick-slide .product::before{
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(#FFFFFF00, #00000000, #00000078, #000000D1);
  opacity: 100%;
  z-index: 999;
} */
#our-products-sec .slick-slider .slick-list .slick-slide .product p {
  position: absolute;
  /* background: #fff; */
  padding: 0px;
  text-align: center;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  height: 0;
  transition: all 0.5s;
  /* color: var(--color-blue); */
  font-size: 20px;
  color: #ffffff;
}
#our-products-sec .slick-slider .slick-list .slick-slide .product:hover p {
  height: auto;
  transition: all 0.5s;
  padding: 5px 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
#our-products-sec
  .slick-slider
  .slick-list
  .slick-slide
  .product
  .product-content {
  position: relative;
  z-index: 999;
}
#our-products-sec
  .slick-slider
  .slick-list
  .slick-slide
  .product
  img.product-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  /* min-height: 420px!important; */
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
#our-products-sec .slick-slider .slick-list .slick-slide .product-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
}
#our-products-sec .slick-slider .slick-list .slick-slide .product-content img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  padding: 5px;
}
#our-products-sec
  .slick-slider
  .slick-list
  .slick-slide
  .product-content
  h4.product-name {
  font-weight: 300;
  font-size: 24px;
  font-family: var(--font-family-italic);
  color: #4488d2;
  width: 100%;
  text-align: center;
  margin: 5px 0;
}
#our-products-sec .product-sec-desc .slick-buttons {
  margin-top: 200px;
  position: absolute;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  right: 0;
}
#our-products-sec .product-sec-desc .slick-buttons button {
  width: 50px;
  height: 50px;
  background: #4589d3;
  box-shadow: 0 0 10px #adadad;
  border-radius: 50px;
}
#our-products-sec .product-sec-desc .slick-buttons button svg {
  width: 30px;
  height: 30px;
}
/* #our-products-sec .slick-slider .slick-list .slick-slide.slick-active.slick-current .product p{
  height: auto;
  transition: all 0.5s;
  padding: 30px 15px;
} */

/* .slick-buttons{
  display: none;
} */
#our-products-sec a {
  text-decoration: none;
}
#our-products-sec div.product img.product-logo {
  background-color: #ede7e7;
  margin: 5px 0 325px 5px;
  padding: 10px 20px;
  width: 100px;
}
/* #our-products-sec div.product p.product-desc{
  font-family: var(--font-family-normal);
  font-weight: 400;
  color: #FFFFFF;
  padding: 0 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
} */

/* Our Products Section End */

/* Our Services Section Start*/
/* #our-services-sec{
  background-color: #f8ffff;
  margin-top: 7%;
  margin-bottom: 25px;
}
#our-services-sec nav{
  border: none;
  margin: 0 auto 10px!Important;
  display: flex;
  justify-content: center;
}
#our-services-sec .nav-tabs .nav-link{
  color: #000000;
  border: none;
  font-family: var(--font-family-normal);
  font-weight: 400;
  font-size: 15px;
  width: 200px;
  min-height: 60px;
  margin: 5px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #cecece;
}
#our-services-sec .nav-tabs .nav-link.active{
  background-color: var(--color-blue);
  color: #fff;
  box-shadow: -1px 1px 15px #08A8F27A;
  border-radius: 4px;
  opacity: 1;
}
#our-services-sec .service-desc{
  background: var(--gradient-blue);
  padding: 50px;
  color: #FFFFFF;
  text-align: left;
}
#our-services-sec .service-desc h2{
  font-size: 30px;
  font-family: var(--font-family-normal);
  font-weight: 500;
  letter-spacing: 0.1rem;
  color: #ffffff;
}
#our-services-sec .service-desc p{
  font-size: 18px;
  font-weight: 300;
  font-family: var(--font-family-normal);
  color: #ffffff;
}
#our-services-sec .service-img{
  display: flex;
  align-items: center;
  justify-content: center;
}
#our-services-sec .service-img div{
  width: 300px;
  height: 300px;
  margin: 0 auto;
}
#our-services-sec .service-img img{
  height: 500px;
  max-width: calc(100% - 50px);
  margin: 15px auto;
  max-height: 100%;
}
#our-services-sec .service-img div span{
  height: 100%;
  width: 100%;  
  display: inline-block;
  background: url('./media/services-img.webp') no-repeat;
}
#our-services-sec .service-img div span.serviceservice4 { 
  background-position: 0% 0%;
  background-size: 300px 1450px;
}
#our-services-sec .service-img div span.serviceservice5 { 
  background-position: 0% 26.232394%; 
  background-size: 300px 1430px; 
}
#our-services-sec .service-img div span.serviceservice1 { 
  background-position: 0% 52.2%;
  background-size: 300px 1430px;
}
#our-services-sec .service-img div span.serviceservice2 {
  background-position: 0% 78%;
  background-size: 300px 1450px;
}
#our-services-sec .service-img div span.serviceservice6 { 
  background-position: 0% 100%;
  background-size: 375px 1700px;
}

#our-services-sec .service-img img{
  width: 80%;
}
#our-services-sec .service-img svg{
  max-width: 500px;
  margin: 10px auto;
  height: auto;
} */

.expertize-sec {
  padding: 50px 0;
}
.expertize-sec h2 {
  color: #08f;
  font-weight: 700;
  font-size: 40px;
  margin: 0 0 15px 0;
}
.expertize-sec .expertize-items {
  padding: 0 150px;
}
.expertize-sec .expertize-items .expertize-item {
  margin: 10px auto;
  padding: 30px;
  transition: all 0.2s ease-in;
  position: relative;
  height: 300px;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 15px;
  border: 3px solid #65c8f7;
}
/* .expertize-sec .expertize-items .expertize-item:hover {
  transition: all 0.2s ease-out;
  transform: scale(1.05);
  background: #08a8f2;
  box-shadow: 0 0 10px #ececec;
} */
.expertize-sec .expertize-items .expertize-item h4 {
  color: #202020;
  font-weight: 700;
  font-size: 20px;
  margin: 0px;
}
.expertize-sec .expertize-items .expertize-item h4 .expertize-label {
  margin-right: 10px;
  font-weight: 500;
  font-size: 20px;
}
.expertize-sec .expertize-items .expertize-item h4 span.expertize-icon {
  padding: 10px;
  background: #c5e4ff;
  border-radius: 50%;
  display: inline-flex;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
}
.expertize-sec .expertize-items .expertize-item h4 span.expertize-icon svg {
  width: 40px;
  height: 40px;
}
/* .expertize-sec .expertize-items .expertize-item:hover h4 span.expertize-icon {
  background: #55a9f3;
  transition: all 0.5s ease-out;
} */
.expertize-sec .expertize-items .expertize-item p {
  color: #202020;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.5s ease-out;
}
/* .expertize-sec .expertize-items .expertize-item:hover h4 {
  color: #ffffff;
  transition: all 0.5s ease-out;
}
.expertize-sec .expertize-items .expertize-item:hover p {
  color: #ffffff;
  transition: all 0.5s ease-out;
} */
/* .expertize-sec .expertize-items .expertize-item:hover h4 span.expertize-icon {
  padding: 10px;
  background: #ffffff;
  transition: all 0.5s ease-out;
} */

/* Our Services Section CSS End*/

/* Technologies Section CSS Start */
#technologies-sec {
  position: relative;
  background: linear-gradient(180deg, #346aab, #53a5e7);
}
/* #technologies-sec img.img-bg{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: none;
  z-index: -1;
  opacity: 0.9;
} */
#technologies-sec .technology-content-block h4.title-yellow-bar:before {
  background: #ffffff;
}
#technologies-sec .technology-content-block p {
  color: #ffffff;
}
#technologies-sec .technology-content-block p.technologies-desc {
  font-family: var(--font-family-normal);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
#technologies-sec .technology-list-block ul {
  height: 700px;
  overflow-y: scroll;
}
#technologies-sec .technology-list-block ul::-webkit-scrollbar {
  display: none;
}
#technologies-sec .technology-list-block ul li {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 10px;
  width: 140px;
  height: 14s0px;
  margin: 10px auto;
}
#technologies-sec .technology-list-block ul.list1 li span {
  display: inline-block;
  height: 120px;
  width: 100%;
  background: url("./media/tech-sprite.webp") no-repeat;
  background-position-x: 0px;
}
#technologies-sec .technology-list-block ul.list2 li span {
  display: inline-block;
  height: 120px;
  width: 100%;
  background: url("./media/tech-sprite.webp") no-repeat;
  background-position-x: -144px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech1 {
  background-position-y: -120px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech2 {
  background-position-y: -240px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech3 {
  background-position-y: -360px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech4 {
  background-position-y: -480px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech5 {
  background-position-y: -600px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech6 {
  background-position-y: -720px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech7 {
  background-position-y: -840px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech8 {
  background-position-y: -960px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech9 {
  background-position-y: -1080px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech10 {
  background-position-y: -1200px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech11 {
  background-position-y: -1320px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech12 {
  background-position-y: -1440px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech13 {
  background-position-y: -1560px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech14 {
  background-position-y: -1680px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech15 {
  background-position-y: -1800px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech16 {
  background-position-y: -1920px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech17 {
  background-position-y: -2040px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech18 {
  background-position-y: -2160px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech19 {
  background-position-y: -2280px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech20 {
  background-position-y: -2400px;
}
#technologies-sec .technology-list-block ul.list1 li span.tech21 {
  background-position-y: -2520px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech1 {
  background-position-y: -120px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech2 {
  background-position-y: -240px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech3 {
  background-position-y: -360px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech4 {
  background-position-y: -480px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech5 {
  background-position-y: -600px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech6 {
  background-position-y: -720px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech7 {
  background-position-y: -840px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech8 {
  background-position-y: -960px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech9 {
  background-position-y: -1080px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech10 {
  background-position-y: -1200px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech11 {
  background-position-y: -1320px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech12 {
  background-position-y: -1440px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech13 {
  background-position-y: -1560px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech14 {
  background-position-y: -1680px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech15 {
  background-position-y: -1800px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech16 {
  background-position-y: -1920px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech17 {
  background-position-y: -2040px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech18 {
  background-position-y: -2160px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech19 {
  background-position-y: -2280px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech20 {
  background-position-y: -2400px;
}
#technologies-sec .technology-list-block ul.list2 li span.tech21 {
  background-position-y: -2520px;
}
#technologies-sec .technology-list-block ul li span.lazy-load-image-background {
  width: 100%;
  height: 100%;
}
#technologies-sec .technology-list-block ul li img {
  object-fit: contain;
  width: 100%;
}
#technologies-sec .technology-list-block ul.list1 {
  /* padding-top: 30px; */
}
#technologies-sec .technology-list-block ul.list2 {
  margin-top: 100px;
}
/* Technologies Section CSS End */

/* Our Team Section CSS Start */
#our-team-sec {
  margin-top: 3%;
}
#our-team-sec .slick-buttons {
  margin-top: 300px;
  position: absolute;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  right: 0;
}
#our-team-sec .slick-buttons button {
  width: 50px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0 0 10px #adadad;
  border-radius: 50px;
  border: 3px solid #4589d3;
  color: #4589d3;
}
#our-team-sec .slick-buttons button svg {
  width: 30px;
  height: 30px;
}
#our-team-sec .slick-buttons button svg path {
  fill: #4589d3;
}
/* #our-team-sec .slick-buttons button{
  background-color: #08A8F2;
  color: #FFFFFF;
  border: 3px solid #FFFFFF;
  border-radius: 7px;
  margin: 7px;
} */
#our-team-sec .slick-slider .slick-list .slick-slide .member {
  margin: 0 auto;
  text-align: center;
  max-width: 350px;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member div.light-blue-bg,
#our-team-sec .slick-slider .slick-list .slick-slide .member div.dark-blue-bg,
#our-team-sec .slick-slider .slick-list .slick-slide .member div.yellow-bg {
  position: relative;
  padding-top: 30px;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member div p {
  position: absolute;
  background: #fff;
  padding: 0px;
  text-align: center;
  bottom: 0;
  right: 5px;
  left: 5px;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  color: var(--color-blue);
  font-size: 16px;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member div:hover p {
  height: auto;
  transition: all 0.5s;
  padding: 30px 15px;
}
/* #our-team-sec .slick-slider .slick-list .slick-slide .member div.member-quote-blue p{
  position: absolute;
  background: #fff;
  padding: 0px;
  text-align: center;
  bottom: 0;
  right: 5px;
  left: 5px;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  color: var(--color-blue);
  font-size: 16px;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member div.member-quote-blue:hover p{
  height: auto;
  transition: all 0.5s;
  padding: 30px 15px;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member div.member-quote-dark-blue p{
  position: absolute;
  background: #fff;
  padding: 0px;
  text-align: center;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  color: var(--color-dark-blue);
  font-size: 20px;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member div.member-quote-dark-blue:hover p{
  height: auto;
  transition: all 0.5s;
  padding: 30px 15px;
} */
/* #our-team-sec .slick-slider .slick-list .slick-slide .member div.member-quote-yellow p{
  position: absolute;
  background: #fff;
  padding: 0px;
  text-align: center;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  color: var(--color-yellow);
  font-size: 20px;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member div.member-quote-yellow:hover p{
  height: auto;
  transition: all 0.5s;
  padding: 30px 15px;
} */
#our-team-sec .slick-slider .slick-list .slick-slide .member div::before {
  position: absolute;
  content: "";
  top: 100px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  border: 5px solid #08a8f2;
  /* border-bottom: none; */
  box-shadow: 0 0 10px #ececec;
}
/* #our-team-sec .slick-slider .slick-list .slick-slide .member div.light-blue-bg::before {
  position: absolute;
  content: '';
  top: 100px;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--color-blue);
  z-index: -1;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member div.dark-blue-bg::before{
  position: absolute;
  content: '';
  top: 100px;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--color-dark-blue);
  z-index: -1;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member div.yellow-bg::before{
  position: absolute;
  content: '';
  top: 100px;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--color-yellow);
  z-index: -1;
} */
#our-team-sec .slick-slider .slick-list .slick-slide .member img {
  margin: 0 auto;
  max-width: calc(100% - 30px);
  height: 350px;
  border-bottom: 5px solid #08a8f2;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member h5.member-name {
  font-family: var(--font-family-normal);
  font-weight: 600;
  font-size: 23px;
  color: #000000;
}
#our-team-sec
  .slick-slider
  .slick-list
  .slick-slide
  .member
  h5.member-position {
  font-family: var(--font-family-normal);
  font-weight: 400;
  font-size: 20px;
  color: #08a8f2;
}
/* #our-team-sec .slick-slider .slick-list .slick-slide .member h5.member-position-blue{
  font-family: var(--font-family-normal);
  font-weight: 400;
  font-size: 20px;
  color: #08A8F2;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member h5.member-position-dark-blue{
  font-family: var(--font-family-normal);
  font-weight: 400;
  font-size: 20px;
  color: #10337C;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member h5.member-position-yellow{
  font-family: var(--font-family-normal);
  font-weight: 400;
  font-size: 20px;
  color: #FBB333;
}
#our-team-sec .slick-slider .slick-list .slick-slide .member h5.member-qualification{
  font-family: var(--font-family-normal);
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  opacity: 0.5;
} */
#our-team-sec ul.slick-dots li {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 20px;
  margin: 0;
}
#our-team-sec ul.slick-dots li button {
  width: 35px;
  height: 10px;
  border-radius: 3px;
  background: var(--color-blue);
  opacity: 0.3;
  /* margin-top: 10px; */
}
#our-team-sec ul.slick-dots li button::before {
  color: transparent;
}
#our-team-sec ul.slick-dots li.slick-active button {
  background: var(--color-blue);
  opacity: 1;
}
/* Team Section CSS End */

/* Clients Section CSS Start */
#Partner-sec {
  position: relative;
  padding: 50px 0;
  /* background: linear-gradient(180deg, #08a8f2,#10337C); */
  /* background: #adadad14; */
  background: #9ecdfb;
}
#Partner-sec .title-yellow-bar:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  bottom: -10px;
  background-color: #0f3989 !important;
}
#Partner-sec img.img-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: none;
  z-index: -1;
}
#Partner-sec .client-descript-block {
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 999;
  margin: 0;
}
/* #Partner-sec .client-descript-block:before{
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--gradient-blue-reverse);
  opacity: 0.5;
} */
#Partner-sec .client-list-block {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 20px auto;
  /* background: linear-gradient(180deg, #08a8f2,#10337C); */
  /* background: var(--gradient-blue-reverse); */
}

#Partner-sec .client-list-block .container-fluid{
  justify-content: center;
  display: flex;
  flex-direction: row;
}

#Partner-sec .client-descript-block h4 {
  color: #0f3989;
}
#Partner-sec .client-descript-block p {
  color: #535151;
}

#Partner-sec .client-descript-block div {
  color: white;
}

/* #Partner-sec .client-list-block:before{
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--gradient-blue-reverse);
  opacity: 0.5;
  z-index: -1;
} */
#Partner-sec ul {
  /* background: #ffffff; */
  /* box-shadow: 0 0 10px #ececec; */
}
#Partner-sec ul li {

  border-radius: 6px;
  background: #fff;
  margin: 5px;
  padding: 10px;
  height: 120px;
  width: 260px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fbf6f6;
  box-shadow: 1px 1px 9px #00000024;
  transition: all 0.3s;
  overflow: hidden;

}
#Partner-sec ul li a{

  color: #9ecdfb;
  transition: all 0.3s;
  text-decoration: none !important; 
}

#Partner-sec ul li:hover {
  transform: translateY(-20px);
  background-position: center 100%;
  background-size: 105%;
  box-shadow: 0px 0px 20px 0px rgba(119, 119, 119, 0.6);
}
#Partner-sec ul li:hover .card__button {
  transform: translateY(0%);
  color: #0f3989;
  transition: all 0.3s;
}
.card__button {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  background-color: #9ecdfb;
  box-sizing: border-box;
  padding: 20px 10px;
  font-size: 12px;
  color: #9ecdfb;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  transform: translateY(100%);
  transition: all 0.3s;
}
#Partner-sec ul li img {
  max-width: 150px;
  max-height: 100px;
  object-fit: contain;
}
/* #Partner-sec ul li span {
  display: inline-block;
  width: 100%;
  height: 75px; 
  height: 100%;
  background: url("./media/client-logos.webp") no-repeat;
} */
#Partner-sec ul li span.client-logo1 {
  background-position: 30px 10px;
  background-size: 175px 350px;
}
#Partner-sec ul li span.client-logo2 {
  background-position: 10px -95px;
  background-size: 200px 400px;
}
#Partner-sec ul li span.client-logo3 {
  background-position: 20px -165px;
  background-size: 190px 400px;
}
#Partner-sec ul li span.client-logo4 {
  background-position: 35px -190px;
  background-size: 175px 330px;
}
#Partner-sec ul li span.client-logo5 {
  background-position: -10px -328px;
  background-size: 245px 400px;
}
#Partner-sec .client-list-block .image-group{

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: white;
    padding: 12px;
    border-radius: 8px;
    margin : 12px
}
/* #clients-sec ul li span.client-logo4:before {
  position: absolute;
  content: '';
  top: 45px;
  background: #ffffff;
  left: 0;
  right: 0;
  height: 15px;
} */

/* Clients Section CSS End */
/* Clients Section CSS Start */
#clients-sec {
  position: relative;
  padding: 50px 0;
  /* background: linear-gradient(180deg, #08a8f2,#10337C); */
  background: #adadad14;
}
#clients-sec img.img-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: none;
  z-index: -1;
}
#clients-sec .client-descript-block {
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 999;
  margin: 0;
}
/* #clients-sec .client-descript-block:before{
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--gradient-blue-reverse);
  opacity: 0.5;
} */
#clients-sec .client-list-block {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 20px auto;
  /* background: linear-gradient(180deg, #08a8f2,#10337C); */
  /* background: var(--gradient-blue-reverse); */
}
#clients-sec .client-descript-block h4 {
  color: #08a8f2
}
#clients-sec .client-descript-block p {
  color: #000000;
}
/* #clients-sec .client-list-block:before{
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--gradient-blue-reverse);
  opacity: 0.5;
  z-index: -1;
} */
#clients-sec ul {
  /* background: #ffffff; */
  /* box-shadow: 0 0 10px #ececec; */
}
#clients-sec ul li {
  background: #fff;
  margin: 0;
  padding: 10px;
  height: 100px;
  width: 250px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fbf6f6;
  box-shadow: 0 0 10px #ececec;
  /* width: 33.333%; */
  /* min-width: 200px;*/
}
#clients-sec ul li img {
  max-width: 150px;
  max-height: 100px;
  object-fit: contain;
}
/* #clients-sec ul li span {
  display: inline-block;
  width: 100%;
  height: 75px; 
  height: 100%;
  background: url("./media/client-logos.webp") no-repeat;
} */
#clients-sec ul li span.client-logo1 {
  background-position: 30px 10px;
  background-size: 175px 350px;
}
#clients-sec ul li span.client-logo2 {
  background-position: 10px -95px;
  background-size: 200px 400px;
}
#clients-sec ul li span.client-logo3 {
  background-position: 20px -165px;
  background-size: 190px 400px;
}
#clients-sec ul li span.client-logo4 {
  background-position: 35px -190px;
  background-size: 175px 330px;
}
#clients-sec ul li span.client-logo5 {
  background-position: -10px -328px;
  background-size: 245px 400px;
}
#clients-sec ul li span.client-logo6 {
  background-position: 35px -190px;
  background-size: 245px 400px;
}
/* #clients-sec ul li span.client-logo4:before {
  position: absolute;
  content: '';
  top: 45px;
  background: #ffffff;
  left: 0;
  right: 0;
  height: 15px;
} */

/* Clients Section CSS End */

/* Testimonials Section CSS Start */
#testo-sec {
  padding: 50px 0;
}
/* #testo-sec .slick-slider{
  padding: 0 100px;
} */
#testo-sec .slick-buttons {
  margin-top: 300px;
  position: absolute;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  right: 0;
}
#testo-sec .slick-buttons button {
  width: 50px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0 0 10px #adadad;
  border-radius: 50px;
  border: 3px solid #4589d3;
  color: #4589d3;
}
#testo-sec .slick-buttons button svg {
  width: 30px;
  height: 30px;
}
#testo-sec .slick-buttons button svg path {
  fill: #4589d3;
}
/* #testo-sec .slick-buttons button{
  background: #08A8F2;
  color: #FFFFFF;
  border: 3px solid #FFFFFF;
  border-radius: 7px;
  margin: 7px;
} */
#testo-sec .testo-content {
  position: relative;
  margin: 100px auto 25px;
  border-radius: 10px;
  background: #ffffff;
  color: #55a9f3;
  border: 5px solid #55a9f3;
  max-width: calc(100% - 100px);
}
#testo-sec p.testo-img-p {
    width: 150px;
    border-radius: 0%;
    position: absolute;
    top: -100px;
    left: calc(50% - 80px);
    right: 0;
    background: #ffffff;
}
#testo-sec p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
#testo-sec p.testo-name {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
  margin-bottom: 15px;
  display: inline-block;
}
#testo-sec p.testo-designation {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
#testo-sec p.testo-desc {
  color: #000000;
  font-size: 16px;
  line-height: 1.5;
}
#testo-sec p.testo-desc svg {
  margin: 0 5px 15px;
  width: 12px;
}
#testo-sec .more-btn {
  background-color: transparent;
  border: 2px solid #55a9f3;
  color: #ffffff;
  padding: 10px 40px;
  background: #55a9f3;
}
#testo-sec ul.slick-dots li {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 20px;
  margin: 0;
}
#testo-sec ul.slick-dots li button {
  width: 35px;
  height: 10px;
  border-radius: 3px;
  background: var(--color-blue);
  opacity: 0.3;
}
#testo-sec ul.slick-dots li button::before {
  color: transparent;
}
#testo-sec ul.slick-dots li.slick-active button {
  background: var(--color-blue);
  opacity: 1;
}
/* Testimonials Section CSS End */

/* Engagement Model Section CSS Start */
#engag-models-sec {
  /* padding: 50px 0; */
}
#engag-models-sec .engag-model-content p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  font-family: var(--font-family-normal);
  font-size: 16px;
  font-weight: 400;
  opacity: 0.8;
  letter-spacing: 0.33px;
  line-height: 2;
}
#engag-models-sec .engag-model-content .more-btn {
  background-color: #08a8f2;
  border: 2px solid #ffffff;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 40px;
  margin: 5px auto;
  text-decoration: none;
  display: inline-block;
}
/* Engagement Model Section CSS Start */

/* RFQ Section CSS Start */
.rfq-banner-sec {
  padding: 100px;
  background: linear-gradient(90deg, #19337c, #55a9f3);
}
.rfq-banner-sec .banner-content h3 {
  font-size: 50px;
  color: #ffffff;
  text-transform: capitalize;
  position: relative;
  padding-top: 30px;
  display: inline-block;
  margin-bottom: 0;
}
.rfq-banner-sec .banner-content h6 {
  color: #f1b330;
  font-size: 24px;
  margin: 15px auto;
}
.rfq-banner-sec .banner-content p {
  color: #ffffff;
  font-size: 24px;
}
.rfq-banner-sec .banner-img-area {
  position: relative;
  text-align: center;
  overflow: hidden;
}
.rfq-banner-sec .banner-img-area .main-img {
  position: relative;
  width: 250px;
  margin: 0 auto;
  background: #000000;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 15px #adadad inset;
}
#rfq-sec {
  padding: 50px 0;
}
#rfq-sec .rfq-sec {
  position: relative;
  margin-top: 2%;
  padding: 2% 7%;
  background: linear-gradient(#10337c, #08a8f2);
}
#rfq-sec .rfq-sec-bg {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}
#rfq-sec .rfq-content-block {
  background: #ffffff;
}
#rfq-sec .rfq-content-block .rfq-content {
  background: linear-gradient(#08a8f2, #10337c);
  font-family: var(--font-family-normal);
  color: #ffffff;
  padding: 10px;
  letter-spacing: 0.46px;
}
#rfq-sec .rfq-content-block .rfq-content h5 {
  font-size: 20px;
}
#rfq-sec .rfq-content-block .rfq-content h4 {
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
}
#rfq-sec .rfq-content-block .rfq-content p {
  font-size: 15px;
  font-style: italic;
}
#rfq-sec .rfq-content-block .rfq-content img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 30px;
}
#rfq-sec .rfq-content-block .rfq-form h4 {
  font-family: var(--font-family-normal);
  color: #ffffff;
  font-size: 20px;
}
#rfq-sec .rfq-content-block .rfq-form h4.success-msg {
  color: #adadad;
}
/* #rfq-sec .rfq-content-block .rfq-form nav{
  border: none;
} */
/* #rfq-sec .rfq-content-block .rfq-form ul li{
  color: #000000;
  border: none;
  font-family: var(--font-family-normal);
  font-weight: 400;
  font-size: 16px;
} */
#rfq-sec h4.active-item {
  background-color: var(--color-blue);
  color: #fff;
  box-shadow: -1px 1px 15px #08a8f27a;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}
#rfq-sec .rfq-content-block .rfq-form .next-btn {
  background-color: #08a8f2;
  border: 2px solid #08a8f2;
  border-radius: 7px;
  color: #ffffff;
  padding: 10px 40px;
  min-width: 20%;
  margin: 35px 15px;
}
#rfq-sec .rfq-content-block .rfq-form .back-btn {
  background-color: #6c757d;
  border: 2px solid #6c757d;
  border-radius: 7px;
  color: #ffffff;
  padding: 10px 40px;
  min-width: 20%;
  margin: 35px 15px;
}
#rfq-sec .success-msg {
  font-weight: 300;
  font-size: 22px;
  font-family: var(--font-family-italic);
  display: inline-block;
  color: var(--color-blue);
}
/* RFQ Section CSS Start */

/* Footer CSS Start */
#footer {
  background-color: #8f8f8f0d;
  padding: 25px;
}
/* #footer a .active svg image{
  width: 70px;
    height: 70px;
} */
#footer h2 {
  font-family: var(--font-family-normal);
  color: #000000;
  text-transform: uppercase;
  font-size: 20px;
}
#footer .nav-link,
#footer p {
  font-family: var(--font-family-normal);
  font-weight: 400;
  color: #000000;
  font-size: 15px;
}
#footer .globe-icon {
  width: 20px;
  height: 20px;
}
/* Footer CSS End */

/* About Us Page CSS Start */
.about-banner-sec {
  padding: 100px;
  background: linear-gradient(90deg, #19337c, #55a9f3);
}
.about-banner-sec .banner-content h3 {
  font-size: 50px;
  color: #ffffff;
  text-transform: capitalize;
  position: relative;
  padding-top: 30px;
  display: inline-block;
  margin-bottom: 0;
}
.about-banner-sec .banner-content h6 {
  color: #f1b330;
  font-size: 24px;
  margin: 15px auto;
}
.about-banner-sec .banner-content p {
  color: #ffffff;
  font-size: 24px;
}
.about-banner-sec .banner-img-area {
  position: relative;
  text-align: center;
}

.about-banner-sec #shape {
  position: relative;
  margin: 0 auto;
  border-radius: 50%;
  width: 125px;
  height: 125px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-5deg) rotateY(-30deg);
  -moz-transform: rotateX(-5deg) rotateY(-30deg);
  transform: rotateX(-5deg) rotateY(-30deg);
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  transition: transform 2s;
}
.about-banner-sec #shape {
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: rotateX(-5deg) rotateY(-30deg);
  -moz-transform: rotateX(-5deg) rotateY(-30deg);
  transform: rotateX(-5deg) rotateY(-30deg);
  -webkit-animation: spin-changer 60s infinite alternate-reverse;
  -moz-animation: spin-changer 60s infinite alternate-reverse;
  animation: spin-changer 60s infinite alternate-reverse;
}
.about-banner-sec #shape > div {
  cursor: pointer;
  position: absolute;
  width: 130px;
  height: 130px;
  border: 5px solid white;
  overflow: hidden;
  opacity: 0.85;
  border-radius: 5px;
}
.about-banner-sec #shape a {
  color: black;
  text-decoration: none;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-banner-sec #shape > div p {
  padding: 5px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  color: #ffffff;
  margin: 0;
  overflow: hidden;
  transition: all 2s ease-in-out;
  opacity: 0;
}
.about-banner-sec #shape > div:hover p {
  opacity: 1;
  transition: all 2s ease-in-out;
}
.about-banner-sec #shape .ft {
  background: #aedaed;
  -webkit-transform: translateZ(63px);
  -moz-transform: translateZ(63px);
  transform: translateZ(63px);
}
.about-banner-sec #shape .rt {
  background: #aedaed;
  -webkit-transform: rotateY(90deg) translateZ(63px);
  -moz-transform: rotateY(90deg) translateZ(63px);
  transform: rotateY(90deg) translateZ(63px);
}
.about-banner-sec #shape .bk {
  background: #aedaed;
  -webkit-transform: rotateY(180deg) translateZ(63px);
  -moz-transform: rotateY(180deg) translateZ(63px);
  transform: rotateY(180deg) translateZ(63px);
}
.about-banner-sec #shape .lt {
  background: #aedaed;
  -webkit-transform: rotateY(270deg) translateZ(63px);
  -moz-transform: rotateY(270deg) translateZ(63px);
  transform: rotateY(270deg) translateZ(63px);
}
.about-banner-sec #shape .tp {
  background: #aedaed;
  -webkit-transform: rotateX(90deg) translateZ(63px);
  -moz-transform: rotateX(90deg) translateZ(63px);
  transform: rotateX(90deg) translateZ(63px);
}
.about-banner-sec #shape .bm {
  background: #aedaed;
  -webkit-transform: rotateX(270deg) rotateY(0deg) translateZ(63px);
  -moz-transform: rotateX(270deg) rotateY(0deg) translateZ(63px);
  transform: rotateX(270deg) rotateY(0deg) translateZ(63px);
}
/* .about-banner-sec .banner-img-area .main-img{
  width: 50%;
  margin: 0 auto;
  opacity: 0.3;
  transition: all 0.2s;
} */
.about-banner-sec .banner-img-area .main-img {
  position: relative;
  width: 300px;
  margin: 0 auto;
  /* animation-name: bulb;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-iteration-count: infinite; */
}
.about-banner-sec .banner-img-area:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 55%;
  width: 300px;
  border-radius: 50%;
  margin: 0 auto;
  z-index: 0;
  /* opacity: 0; */
  box-shadow: 0 0 150px #ffffff;
  /* transition: all 1s; */
  background: #ffffff8c;
  animation-name: bulb;
  animation-duration: 6s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}
/* .about-banner-sec .banner-img-area:hover:before{
  opacity: 1;
} */
.about-banner-sec .banner-img-area .right-img {
  position: absolute;
  right: 70px;
  bottom: calc(50% - 50px);
  width: 100px;
  animation-name: bulb;
  animation-duration: 6s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  /* opacity: 0;
  transition: all 1s; */
}
.about-banner-sec .banner-img-area .left-img {
  position: absolute;
  left: 70px;
  bottom: calc(50% - 50px);
  width: 100px;
  animation-name: bulb;
  animation-duration: 6s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  /* opacity: 0;
  transition: all 1s; */
}
/* .about-banner-sec .banner-img-area:hover .right-img{
  transition: all 1s;
  opacity: 1;  
}
.about-banner-sec .banner-img-area:hover .left-img{
  transition: all 1s;
  opacity: 1;  
} */
#about-us-sec {
  margin: 3% 0;
}
#about-us-sec .about-us-content p {
  font: var(--font-family-normal);
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.05rem;
}
#about-us-sec .about-us-content .about-us-img img {
  border-radius: 10px;
}
#about-us-sec .our-impact-categories {
  background: var(--gradient-blue);
}
#about-us-sec .our-impact-categories .impact-cat {
  border: 3px solid #08a8f2;
  background: #ffffff;
  border-radius: 8px;
  padding: 25px 50px;
  width: 280px;
}
#about-us-sec .our-impact-categories .impact-cat h2 {
  font-family: var(--font-family-normal);
  font-weight: bolder;
  font-size: 50px;
  color: #08a8f2;
}
#about-us-sec .our-impact-categories .impact-cat p {
  font: var(--font-family-italic);
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  letter-spacing: 0.05rem;
}
#about-us-sec .our-impact-content {
  font: var(--font-family-normal);
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.05rem;
}
#about-us-sec .our-impact-content .contact-div{
  box-shadow: 0px 3px 24px #00000017;
  border: 0.5px solid #00000021;
  border-radius: 8px;
}
#about-us-sec .our-impact-content a{
  color: #000000;
}
#about-us-sec .our-industries-categories {
  margin-top: 20px;
  /* margin-bottom: 15%; */
}
#about-us-sec .our-industries-categories .industry-cat {
  background: #ffffff;
  border-radius: 8px;
  padding: 25px 50px;
  width: 280px;
  border-color:#08a8f2 !important
}
#about-us-sec .our-industries-categories .industry-cat img {
  width: 50px;
}
#about-us-sec .our-industries-categories .industry-cat p {
  font: var(--font-family-normal);
  font-weight: 400;
  font-size: 17px;
  color: #000000;
  letter-spacing: 0.05rem;
  margin-top: 1.5rem;
}
#about-us-sec .our-industries-categories .industry-cat-light-blue {
  border: 3px solid #08a8f2;
}
#about-us-sec .our-industries-categories .industry-cat-red {
  border: 3px solid #e10036;
}
#about-us-sec .our-industries-categories .row1 .industry-cat-light-green {
  border: 3px solid #08f2a2;
}
#about-us-sec .our-industries-categories .industry-cat-yellow {
  border: 3px solid #fbb333;
}
#about-us-sec .our-industries-categories .industry-cat-dark-blue {
  border: 3px solid #0808f2;
}
#about-us-sec .our-industries-categories .row2 .industry-cat-light-green {
  border: 3px solid #08f2a2;
}
#about-us-sec .our-industries-categories .industry-cat-purple {
  border: 3px solid #6d08f2;
}
#about-us-sec .our-industries-categories .industry-cat-maroon {
  border: 3px solid #b50000;
}
#about-us-sec .our-industries-categories .industry-cat-dark-green {
  border: 3px solid #59dc2b;
}
#about-us-sec .our-industries-categories .row3 .industry-cat-light-green {
  border: 3px solid #08f2a2;
}
#about-us-sec .our-industries-categories .industry-cat-light-purple {
  border: 3px solid #b32dff;
}
#about-us-sec .our-industries-categories .row5 .industry-cat-light-green {
  border: 3px solid #08f2a2;
  /* margin-top: 50px; */
}

/* #about-us-sec .our-industries-categories .industry-cat-dark-blue{
  border: 3px solid #08F2A2;
} */
/* About Us Page CSS End */

/* Contact Us Page CSS Start */
.contact-banner-sec {
  padding: 50px;
  background: linear-gradient(90deg, #19337c, #55a9f3);
}
.contact-banner-sec .banner-content h3 {
  font-size: 50px;
  color: #ffffff;
  text-transform: capitalize;
  position: relative;
  padding-top: 30px;
  display: inline-block;
  margin-bottom: 0;
}
.contact-banner-sec .banner-content h6 {
  color: #f1b330;
  font-size: 24px;
  margin: 15px auto;
}
.contact-banner-sec .banner-content p {
  color: #ffffff;
  font-size: 24px;
}
.contact-banner-sec .banner-img-area {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: initial;
  max-width: 500px;
  margin: 0 auto;
}
.contact-banner-sec .banner-img-area span {
  position: relative;
  width: 120px;
}
.contact-banner-sec .banner-img-area span::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  z-index: 9999999;
  left: 5px;
  top: 5px;
  background: #4a8ff9;
  border-radius: 50%;
}
.contact-banner-sec .banner-img-area span:nth-child(even):before {
  top: 5px;
}
.contact-banner-sec .banner-img-area span img {
  width: 100%;
  margin: 0 auto;
  animation-name: swing-paper-right;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  background: #f0f0f0;
  box-shadow: 0 0 10px #e8e6e7 inset;
  border-radius: 5px;
  transform-origin: 0% 0%;
}
.contact-banner-sec .banner-img-area span:nth-child(even) img {
  width: 100%;
  margin: 0 auto;
  animation-name: swing-paper-left;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}
.map-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}
#contact-us-sec .nav-tabs {
  display: flex;
  justify-content: center;
  border-bottom: none;
}
#contact-us-sec .nav-tabs a {
  font-size: 20px;
  color: #495057;
}
#contact-us-sec .nav-tabs a:hover{
  border: none;
}
#contact-us-sec .nav-tabs a.active{
  font-size: 22px;
    font-family: var(--font-family-italic);
    display: inline-block;
    color: var(--color-blue);
    border: none;
    border-bottom: 2px solid #08a8f2;
}
#contact-us-sec .contact-div {
  box-shadow: 0px 3px 24px #00000017;
  border: 0.5px solid #00000021;
  border-radius: 8px;
}
#contact-us-sec .contact-details {
  padding: 20px;
  width: 90%;
  height: 100%;
  /* margin: 0 25px; */
}
#contact-us-sec .contact-details img{
  width: 44px;
}
#contact-us-sec .contact-details h4 {
  font-family: var(--font-family-normal);
  font-size: 17px;
  color: #495057;
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font-family-italic);
  display: inline-block;
}
#contact-us-sec .contact-details h4.contact-num {
  color: rgb(96 96 96)
}
#contact-us-sec .contact-details h4.location {
  color: rgb(96 96 96);
}
#contact-us-sec .contact-details h4.email-address {
  color: #015aff;
}
#contact-us-sec .contact-details span {
  font-family: var(--font-family-normal);
  color: rgb(96 96 96);
}
#contact-us-sec .contact-us-form {
  box-shadow: 0px 3px 24px #00000017;
}
#contact-us-sec .contact-us-form .contact-btn {
  background-color: #08a8f2;
  border: 2px solid #08a8f2;
  border-radius: 7px;
  color: #ffffff;
  padding: 10px 40px;
  min-width: 20%;
  margin-top: 35px;
}
#contact-us-sec #map {
  min-height: 400px;
}
#contact-us-sec .copyUrl {
  display: inline-block;
  position: relative;
  bottom: 85%;
  left: 15px;
}
#contact-us-sec .copyUrl > button {
  color: #4d97f3;
  background: #fff;
  border: none;
  border-radius: 0px;
  box-shadow: 0 0 10px #4d97f3;
  width: 150px;
  padding: 10px;
  font-weight: 500;
  transition: all 0.2s;
  position: relative;
  z-index: 9999;
  opacity: 0.5;
}
#contact-us-sec .copyUrl > button:hover {
  background: #4d97f3;
  color: #fff;
  transition: all 0.2s;
}
#contact-us-sec .copyUrl input {
  opacity: 0.0001;
  position: absolute;
  left: 0;
  right: 0;
}
/* Contact Us Page CSS End */

/* Career Page CSS Start */
.career-banner-sec {
  padding: 100px;
  background: linear-gradient(90deg, #19337c, #55a9f3);
  overflow: hidden;
}
.career-banner-sec .banner-content h3 {
  font-size: 50px;
  color: #ffffff;
  text-transform: capitalize;
  position: relative;
  padding-top: 30px;
  display: inline-block;
  margin-bottom: 0;
}
.career-banner-sec .banner-content h6 {
  color: #f1b330;
  font-size: 24px;
  margin: 15px auto;
}
.career-banner-sec .banner-content p {
  color: #ffffff;
  font-size: 24px;
}
.career-banner-sec .banner-img-area {
  position: relative;
  text-align: center;
  /* overflow: hidden; */
}
.career-banner-sec .banner-img-area .main-img {
  position: relative;
  width: 300px;
  margin: 0 auto;
  animation-name: career-crowd;
  animation-duration: 15s;
  animation-delay: 3s;
  animation-iteration-count: infinite;
}
.career-banner-sec .banner-img-area .left-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  transform: rotate(0);
  animation-name: career;
  animation-duration: 15s;
  animation-delay: 3s;
  animation-iteration-count: infinite;
}
#career-sec {
  margin: 3% 0;
}
#career-sec .job-overview p.job-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.7;
}
#career-sec .job-overview .job-details-btn {
  background: #08a8f2;
  border: 2px solid;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 25px;
  text-decoration: none;
  margin-top: 10px;
}
/* Career Page CSS End */

/* Job Details Page CSS Start*/
#job-details-sec {
  margin: 3% 0;
}
#job-details-sec .job-details {
  font: var(--font-family-normal);
  font-size: 16px;
  letter-spacing: 0.05rem;
  line-height: 1.7;
  text-align: left;
}
#job-details-sec .job-details h4 {
  color: var(--color-blue);
  font-weight: 500;
  margin: 25px 0;
  font-size: 20px;
}
#job-details-sec .job-details ol li::marker,
#job-details-sec .job-details ol h5 {
  font-family: var(--font-family-italic);
  color: #10337c;
  letter-spacing: 0;
  margin: 15px 0;
  font-size: 1.25rem;
  font-weight: 500;
}
#job-details-sec .job-details ol li ul li::marker {
  color: #000000;
}
#job-details-sec .job-details ol li ul li {
  list-style-type: disc;
}
#job-details-sec .job-details div.note {
  font-family: var(--font-family-normal);
}
#job-details-sec .job-details div.note span {
  color: var(--color-blue);
}
.apply-btn {
  background: #08a8f2;
  border: 2px solid;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 25px;
  text-decoration: none;
  margin-top: 10px;
}
/* Job Details Page CSS End*/

/* Complete Client Testo Page CSS Start */
#complete-testo-sec {
  margin-bottom: 5%;
  overflow-x: hidden;
}
#complete-testo-sec p.testo-img-p {
  display: inline-block;
  width: 150px;
  /* height: 150px; */
  border-radius: 100%;
  /* border: 10px solid #10337c; */
}
#complete-testo-sec p.testo-desc svg {
  margin: 0 5px 15px;
}
#complete-testo-sec p.testo-desc {
  white-space: pre-line;
}
/* Complete Client Testo Page CSS End */

/* Complete Engagement Models Page CSS Start */
#complete-engag-models-sec {
  margin: 3% 0;
}
#complete-engag-models-sec .complete-engag-model-content {
  font: var(--font-family-normal);
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.31px;
  line-height: 1.7;
  text-align: left;
}
#complete-engag-models-sec .complete-engag-model-content ol li::marker,
#complete-engag-models-sec .complete-engag-model-content h5 {
  font-family: var(--font-family-italic);
  color: #10337c;
  letter-spacing: 0;
  font-weight: 700;
  margin: 5px 0;
}
/* Complete Engagement Models Page CSS End */

/* Product Listing Page CSS Start */
#products-listing-sec {
  background: #f8f8f8;
  padding: 70px 0;
}
#products-listing-sec .product-listing .product-details:nth-child(even) {
  flex-direction: row-reverse;
}
/* #products-listing-sec .product-listing .product-details:nth-child(even) .product-image img{
  border-top-right-radius: 0% !important;
  border-top-left-radius: 25% !important;
} */
#products-listing-sec .product-listing .product-details {
  background: var(--gradient-blue-reverse);
  box-shadow: 0 0 10px #adadad;
  border-radius: 10px;
  /* overflow: hidden; */
}
#products-listing-sec .product-listing .product-details .product-image {
  position: relative;
  max-height: 400px;
}
#products-listing-sec .product-listing .product-details .product-image img {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* border-top-right-radius: 25%; */
  /* object-fit: contain; */
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
#products-listing-sec
  .product-listing
  .product-details
  .product-image
  .product-logo {
  position: absolute;
  width: 75px;
  height: 75px;
  left: 15px;
  top: 15px;
  background: #fff;
}
#products-listing-sec
  .product-listing
  .product-details
  .product-image
  .product-logo
  img {
  border-radius: 0px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#products-listing-sec
  .product-listing
  .product-details:nth-child(even)
  .product-image
  .product-logo {
  position: absolute;
  width: 75px;
  height: 75px;
  left: inherit;
  right: 15px;
  top: 15px;
  background: #fff;
}
#products-listing-sec
  .product-listing
  .product-details:nth-child(even)
  .product-image
  .product-logo
  img {
  border-radius: 0px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#products-listing-sec .product-listing .product-details .product-desc {
  padding: 5%;
}
#products-listing-sec
  .product-listing
  .product-details
  .product-desc
  .product-block
  h2 {
  color: #ffffff;
  font-size: 30px;
}
#products-listing-sec
  .product-listing
  .product-details
  .product-desc
  .product-logo
  img {
  width: 20%;
  background-color: #ffffff;
  padding: 10px 25px;
}
#products-listing-sec
  .product-listing
  .product-details
  .product-desc
  .product-overview {
  font-family: var(--font-family-normal);
  color: #ffffff;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0.05rem;
  line-height: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
#products-listing-sec
  .product-listing
  .product-details
  .product-desc
  .more-btn {
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 40px;
  text-decoration: none;
}
/* Product Listing Page CSS End */

/* Product Details Page CSS Start */
#product-details-sec {
  margin: 3% 0;
}
#product-details-sec .product-overview {
  margin: 4% 0;
}
#product-details-sec .product-overview .product-logo {
  width: auto;
  min-width: 100px;
  max-width: 150px;
}
#product-details-sec .product-overview p {
  font-family: var(--font-family-normal);
  font-size: 20px;
  color: #000000;
  margin-top: 2rem;
}
#product-details-sec .product-cover-img {
  background: var(--gradient-blue);
  margin-bottom: 5%;
  border-radius: 10px;
}
#product-details-sec .product-cover-img h2 {
  font-family: var(--font-family-normal);
  color: #ffffff;
  padding: 4% 0 0;
}
#product-details-sec .product-cover-img h4 {
  font-family: var(--font-family-normal);
  color: #ffffff;
  padding: 1% 0 4% 0;
}
#product-details-sec .product-cover-img img.web-cover {
  width: 100%;
  padding: 0 5%;
}
#product-details-sec .product-cover-img img.mob-cover {
  width: 80%;
  padding: 0 5%;
}
#product-details-sec .product-features-list {
  background: var(--gradient-blue);
  font-family: var(--font-family-normal);
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.9px;
  padding: 30px;
  border-radius: 10px;
}
#product-details-sec .product-screens-list img {
  width: 75%;
  max-height: 400px;
  padding: 2% 2% 0;
  object-fit: contain;
}
#product-details-sec .product-screens-list ul.slick-dots li {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 20px;
  margin: 0;
}
#product-details-sec .product-screens-list ul.slick-dots li button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--color-blue);
  opacity: 0.3;
}
#product-details-sec .product-screens-list ul.slick-dots li button::before {
  color: transparent;
}
#product-details-sec
  .product-screens-list
  ul.slick-dots
  li.slick-active
  button {
  background: var(--color-blue);
  opacity: 1;
}
.other-products-sec > h3 {
  font-family: var(--font-family-italic);
  color: var(--color-dark-blue);
  font-size: 50px;
}
/* Product Details Page CSS End */

/* Chatbot CSS Start Here */

.chatBotBtn .MuiAvatar-root {
  border: 1px solid #099de7 !important;
  width: 60px !important;
    height: 60px !important;
}
.chatBotBtn{
  position: fixed;
  z-index: 9999;
  right: 18px;
  bottom: 18px;
  border-radius: 50%;
  padding: 0;
  background: transparent;
  cursor: pointer;
}
.ChatbotView{
transition: width 2s;
  transition-timing-function: ease;
position: fixed;
right: 15px;
  bottom: 15px;
  border-radius: 50%;
  padding: 0;
  width: 50px;
  height: 50px;
  border: none;
  box-shadow: none;
  background: #fff;
  cursor: pointer;

}
.bot-pic{
  position: absolute;
  right: 2px;
  width: 90%;
  bottom: 2px;
  border-radius: 100px;
  border-color: tr;
}
.img.rotate-icon {
  transform: rotate(180deg);
  transition: all 1s;
  position: relative;
  bottom: 4px;
}
/* .chtabotMain .chatBotBtn {
  position: absolute;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  padding: 0;
  width: 50px;
  height: 50px;
  border: none;
  box-shadow: none;
  background: #fff;
  cursor: pointer;
}
.chtabotMain .chatBotBtn img {
  width: 100%;
  transform: rotate(0deg);
  transition: all 1s;
  bottom: 2px;
  position: relative;
}
/* .chtabotMain .chatBotBtn img.boss-pic,
.chtabotMain .chatBotBtn img.rotate-img,
.chtabotMain .chatBotBtn svg.fa-times {
  position: absolute;
  right: 2px;
  width: 90%;
  bottom: 2px;
} */
.chat-foot-main .chatBotBtn svg.fa-times {
  width: 25px;
  right: 12px;
  bottom: 4px;
  transition: all 0.5s;
}
.chtabotMain .chatBotBtn svg path {
  fill: #08a8f2;
}
.chat-foot-main .chatBotBtn.active img.rotate-icon {
  transform: rotate(180deg);
  transition: all 1s;
  position: relative;
  bottom: 4px;
} */




/* Old Styling Of Chatbot */

.chat-foot-main {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  height: 100px;
  transition: all 0.5s;
}
.chat-foot-main.active {
  left: 0;
  right: 0;
  transition: all 0.5s;
}
.chat-foot-main.active iframe {
  height: calc(100vh - 70px);
  width: 100vw;
}
.chat-foot-main #valUrl {
  position: absolute;
  bottom: 70px;
  right: 0;
  border: none;
}
.chat-foot-main .text-align {
  display: inline-flex;
  width: 150px;
}
.chat-foot-main .text-align .loading-text {
  margin: 0;
  margin-left: -35px;
  width: 95%;
  height: 45px;
  font-size: 12px;
  font-weight: 700;
  color: #1259b6;
  padding: 5px 10px;
  background: #ffffff7d;
  border-radius: 50px 50px 0;
  position: absolute;
  top: -10px;
}
.chat-foot-main .pop-chatbot {
  position: absolute;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  padding: 0;
  width: 50px;
  height: 50px;
  border: none;
  box-shadow: none;
  background: #fff;
  cursor: pointer;
}
.chat-foot-main .pop-chatbot img {
  width: 100%;
  transform: rotate(0deg);
  transition: all 1s;
  bottom: 2px;
  position: relative;
}
.chat-foot-main .pop-chatbot img.boss-pic,
.chat-foot-main .pop-chatbot img.rotate-img,
.chat-foot-main .pop-chatbot svg.fa-times {
  position: absolute;
  right: 2px;
  width: 90%;
  bottom: 2px;
}
.chat-foot-main .pop-chatbot svg.fa-times {
  width: 25px;
  right: 12px;
  bottom: 4px;
  transition: all 0.5s;
}
.chat-foot-main .pop-chatbot svg path {
  fill: #08a8f2;
}
.chat-foot-main .pop-chatbot.active img.rotate-icon {
  transform: rotate(180deg);
  transition: all 1s;
  position: relative;
  bottom: 4px;
}
.chat-foot-main .max-chat {
  position: absolute;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  padding: 7px;
  width: 50px;
  height: 50px;
  border: none;
  box-shadow: 0 0 10px #adadad;
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
}
.chat-foot-main .max-chat.active {
  right: 40px;
  width: 70px;
  border-radius: 50px 0px 0px 50px;
  transition: all 0.5s;
}
.chat-foot-main .max-chat svg {
  width: 30px;
}
.chat-foot-main .pop-chatbot:focus {
  border: none;
  outline: none;
}
.chat-foot-main .pop-chatbot svg {
  position: initial;
}
/* Chatbot CSS End Here */
.react-tel-input {
  margin-top: 25px;
}
.react-tel-input .form-control {
  width: 100%;
  height: 50px;
}

/* Media CSS */
@media screen and (max-width: 1400px) {
  #testo-sec .slick-slider {
    padding: 0 30px;
  }
  /* #testo-sec .testo-content{
    margin: 100px 25px 25px;
  } */
}
@media screen and (max-width: 1299px) {
  .expertize-sec .expertize-items {
    padding: 0 150px;
  }
}
@media screen and (max-width: 1199px) {
  .home-banner-sec {
    height: 500px;
  }
  .contact-banner-sec,
  .about-banner-sec,
  .career-banner-sec {
    padding: 50px 15px;
  }
  .contact-banner-sec .banner-img-area,
  .about-banner-sec .banner-img-area {
    margin-top: 30px;
  }
  .about-banner-sec .banner-img-area .main-img,
  .about-banner-sec .banner-img-area:before {
    width: 250px;
  }
  .expertize-sec .expertize-items {
    padding: 0 75px;
  }
  #testo-sec .testo-content {
    margin: 100px auto 25px;
  }
  #products-listing-sec .product-listing .product-details .product-image {
    min-height: initial;
    max-height: 350px;
  }
}
@media screen and (max-width: 1024px) {
  .expertize-sec .expertize-items {
    padding: 0;
  }
  #slider-sec .slick-slider .slick-list .slick-slide .slide-content-block {
    height: auto;
    min-height: 350px;
    padding: 50px 0;
  }
}
@media screen and (max-width: 991px) {
  .navbar-collapse.collapse.show {
    box-shadow: rgb(173 173 173) 0px 0px 10px;
    padding: 10px;
  }
  #header .navbar .navbar-nav a.button {
    margin: 0 auto;
  }
  .home-banner-sec {
    height: 400px;
  }
  #products-listing-sec .product-listing .product-details .product-image {
    min-height: initial;
    max-height: 300px;
  }
  #products-listing-sec
    .product-listing
    .product-details
    .product-desc
    .product-block
    h2 {
    font-size: 20px;
  }
  /* #products-listing-sec .product-listing .product-details{
    border-top-right-radius: 25%;
  }
  #products-listing-sec .product-listing .product-details:nth-child(even){
    border-top-right-radius: 0% !important;
    border-top-left-radius: 25% !important;
  }
  #products-listing-sec .product-listing .product-details .product-image img{
    border-top-right-radius: 25%;
  }
  #products-listing-sec .product-listing .product-details:nth-child(even) .product-image img {
    border-top-right-radius: 0% !important;
    border-top-left-radius: 25% !important;
  } */
  #products-listing-sec
    .product-listing
    .product-details
    .product-desc
    .product-overview {
    line-height: 1.4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  #products-listing-sec
    .product-listing
    .product-details
    .product-desc
    .more-btn {
    display: inline-block;
  }
  #technologies-sec .technology-list-block ul {
    height: 400px;
  }
  #technologies-sec .technology-list-block ul li {
    /* width: 120px;
    height: 120px; */
    margin: 10px auto;
    display: flex;
    align-items: center;
  }
  #technologies-sec .technology-list-block ul.list2 {
    margin-top: 0;
  }
  .technology-list-block {
    max-width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 800px) {
  .technology-content-block {
    /* margin-top: 10%; */
  }
  #our-team-sec .slick-slider .slick-list .slick-slide .member {
    text-align: center;
  }
  #our-team-sec,
  #testo-sec,
  #engag-models-sec {
    /* margin-top: 20%; */
  }
  #rfq-sec .rfq-content-block .rfq-content img {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .title-banner-bar {
    font-size: 40px;
  }
  .title-yellow-bar {
    font-size: 18px;
  }
  .home-banner-sec {
    height: 300px;
  }
  .home-banner-sec div.content-block .sub-title {
    font-size: 18px;
  }
  .home-banner-sec div.content-block p {
    font-size: 22px;
  }
  #our-products-sec .product-sec-desc {
    padding: 50px 0 15px;
  }
  #our-products-sec .product-sec-desc h4.title-yellow-bar:before {
    background: #ffffff;
  }
  #our-products-sec .product-sec-desc .slick-buttons {
    margin-top: 200px;
    position: absolute;
    z-index: 99;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
    right: 0;
  }
  #our-products-sec .product-sec-desc .slick-buttons button {
    width: 50px;
    height: 50px;
    background: #4589d3;
    box-shadow: 0 0 10px #adadad;
    border-radius: 50px;
  }
  #our-products-sec .product-sec-desc .slick-buttons button svg {
    width: 30px;
    height: 30px;
  }
  #our-products-sec
    .slick-slider
    .slick-list
    .slick-slide.slick-active.slick-current
    .product
    p {
    height: auto;
    transition: all 0.5s;
    padding: 30px 15px;
  }
  #our-products-sec .slick-slider .slick-list .slick-slide .product p {
    font-size: 14px;
  }
  #our-services-sec .service-desc {
    padding: 30px;
  }
  .contact-banner-sec .banner-content h3,
  .about-banner-sec .banner-content h3,
  .career-banner-sec .banner-content h3 {
    font-size: 36px;
  }
  .about-banner-sec .banner-img-area .main-img,
  .about-banner-sec .banner-img-area:before {
    width: 175px;
  }
  .about-banner-sec .banner-img-area .right-img {
    right: -20px;
    bottom: calc(50% - 50px);
    width: 50px;
  }
  .about-banner-sec .banner-img-area .left-img {
    left: -10px;
    bottom: calc(50% - 50px);
    width: 50px;
  }
}
@media screen and (max-width: 450px) {
  .home-banner-sec {
    height: 300px;
    min-height: 250px;
    padding: 15px 0;
  }
  #our-team-sec .slick-slider .slick-list .slick-slide .member img {
    height: auto;
    max-height: 350px;
  }
  .technology-content-block {
    padding-left: 10px !important;
  }
  .technology-list-block {
    max-width: 100% !important;
    margin: 0 auto;
    padding: 0px;
  }
  #technologies-sec .technology-list-block ul {
    margin-right: 0 !important;
  }
  #technologies-sec .technology-list-block ul li {
    /* width: 100%!important; */
    padding: 10px 7px;
  }
  .testo-content {
    font-size: 15px;
  }
  #testo-sec .testo-content {
    max-width: 100%;
  }
  .engag-model-block {
    margin: 15px 0;
    width: 100%;
  }
  #engag-models-sec .engag-model-content p {
    font-size: 14px;
  }
  #our-services-sec .nav-tabs .nav-link {
    font-weight: 500;
    font-size: 12px;
    width: 140px;
    min-height: 50px;
    margin: 5px;
    text-align: center;
  }
  #footer {
    padding: 20px 0;
  }
}


/* .image-box {
  width: 300px;
  height: 300px;
  position: relative;
}
.image-box img {
  width: 100%;
  height: 100%;
}
.image-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease-in-out;
}

.image-box:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.image-box:hover img {
  transform: scale(0.9);
} */

.chatbot-buttons{
    margin-left: 6px !important;
    color: white !important;
    background: #429efa !important;
    /* cursor: pointer; */
    margin-bottom: 5vh !important;
    border-radius: 6px !important;
}
.chatbot-buttons:hover{
    margin-left: 6px !important;
    color: white !important;
    background: #429efa !important;
    /* cursor: pointer; */
    margin-bottom: 5vh !important;
    border-radius: 6px !important;
}
.chatbot-buttons .MuiSvgIcon-root{
    font-size: 20px !important;
}
.ant-input-disabled{
  background-color: rgb(255 255 255) !important;
}
/* ---------Sounding Effects----------- */


.sound-waves {
  position: absolute;
}
.sound-waves .sound-arrow{
  position: absolute;
    right: 0;
    top: 2px;
    left: 254px;
}
.sound-arrow .MuiSvgIcon-root{
  color: white !important;
}

.sound-waves ul{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 30px;
    margin-right: 326px;
    border: 2px solid #429efa;
    border-radius: 50px;
    width: 300px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    /* margin: 0; */
    /* cursor: pointer; */
    transition: ease 0.2s;
    position: relative;
    background: #429efa;
}

.sound-waves ul:before{
  /* content: 'Click this button!'; */
  width:100%;
  position:absolute;
  text-align:center;
  font-family:sans-serif;
  color:#fff;
  transition: ease 0.3s;
  bottom:0;
  pointer-events:none;
  z-index:-1;
}

.sound-waves ul:hover::before{
  bottom: -30px;
  color: #fff;
}
.sound-waves ul:active::before{
  content: 'Yeah!';
}

.sound-waves li{
  list-style:none;
  height:30px;
  width:4px;
  border-radius:10px;
  background: #fff;
  margin:0 6px;
  padding:0;
  animation-name: wave1;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition:ease 0.2s;
}

.sound-waves ul div:hover > li{
  background:#fff;
}
.sound-waves ul div:hover{
  background: #545FE5;
}

.sound-waves li:nth-child(2) {
    animation-name: wave2;
    animation-delay:0.2s;
}
.sound-waves li:nth-child(3) {
    animation-name: wave3;
  animation-delay:0.23s;
  animation-duration: 0.4s;
}
.sound-waves li:nth-child(4) {
    animation-name: wave4;
    animation-delay:0.1s;
  animation-duration: 0.3s;
}
.sound-waves li:nth-child(5) {
  animation-delay:0.5s;
}
.sound-waves li:nth-child(6) {
    animation-name: wave2;
  animation-duration: 0.5s;
}
.sound-waves li:nth-child(8) {
    animation-name: wave4;
  animation-delay:0.4s;
  animation-duration: 0.25s;
}
.sound-waves li:nth-child(9) {
    animation-name: wave3;
  animation-delay:0.15s;
}

@keyframes wave1 {
    from {transform:scaleY(1);}
    to {transform:scaleY(0.5);}
}
@keyframes wave2 {
    from {transform:scaleY(0.3);}
    to {transform:scaleY(0.6);}
}
@keyframes wave3 {
    from {transform:scaleY(0.6);}
    to {transform:scaleY(0.8);}
}
@keyframes wave4 {
    from {transform:scaleY(0.2);}
    to {transform:scaleY(0.5);}
}


.avatar-container {
  display: flex;
    position: relative;
    gap: 12px;
    align-items: center;
    height: 55px !important;
}
.MuiAccordionSummary-content{
  margin: 0 !important;
}
/* .Mui-expanded{
  height: 45px !important;
} */
.avatar-container span {
  font-family: sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  color: white;
}

.avatar {
  transition: transform 0.3s, width 0.3s, height 0.3s;
  border: 1px solid #1e7cda !important;
}

.avatar-container.expanded .avatar {
  transform: scale(2); /* Adjust the scale as needed */
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .MuiAccordionSummary-root{
  background: #2196f3 !important;
} */
.avatar-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.avatar-container.expanded .avatar-overlay {
  display: block;
}

.chat-simple span {
    margin-left: 12px;
    color: #7b7f7f;
    padding: 1px;
    font-size: 10px;
    word-wrap: break-word;
    align-self: flex-end;
    font-weight: 500;
    /* margin-right: 2%; */
    border-radius: 10px;
}
.chat-extent span {
    /* margin-left: 12px; */
    color: #7b7f7f;
    padding: 1px;
    font-size: 10px;
    word-wrap: break-word;
    align-self: flex-end;
    font-weight: 500;
    /* margin-right: 2%; */
    border-radius: 10px;
    float: right;
    margin-top: 18px;
}